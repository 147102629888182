import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Button from '../Button/Button';

const classNames = require('classnames');

export class AddProductPopup extends Component {
    render() {
        if (this.props.show === true)
            return (
                <div className={classNames('add-product-popup-wrapper', this.props.type, this.props.className)}>
                    <div className={classNames('add-product-popup', { 'add-product-popup-big': this.props.bigPopup === true })}>
                        {
                            this.props.renderHeader ?
                                <div className="add-product-popup-header">
                                    {this.props.renderHeader()}
                                </div>
                                :
                                <div className="add-product-popup-header">
                                    {/* <span className="upbox-popup-header-span">{this.props.header}</span> */}
                                    {
                                        this.props.header &&
                                this.props.headerButtons.map((x, i) => (
                                    <Button
                                        key={i}
                                        {...x}/>
                                ))
                                    }
                                </div>
                        }
                        <div className="add-product-popup-content">
                            {
                                this.props.renderContent ?
                                    this.props.renderContent()
                                    :
                                    this.props.text
                            }
                        </div>
                        <div className="add-product-popup-footer">
                            {
                                this.props.renderFooter ?
                                    this.props.renderFooter()
                                    :
                                    this.props.footerButtons.map((x, i) => (
                                        <Button
                                            key={i}
                                            {...x}/>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            );
        return null;
    }
}

AddProductPopup.defaultProps = {
    show: false,
    header: null,
    text: null,
    headerButtons: [],
    footerButtons: [],
    renderHeader: null,
    renderContent: null,
    renderFooter: null,
    color: '#333',
};
AddProductPopup.propTypes = {
    show: PropTypes.bool,
    bigPopup: PropTypes.bool,
    header: PropTypes.string,
    text: PropTypes.string,
    headerButtons: PropTypes.array,
    footerButtons: PropTypes.array,
    renderHeader: PropTypes.func,
    renderContent: PropTypes.func,
    renderFooter:PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    type:PropTypes.string,
};

export default AddProductPopup;
