import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Button, HeaderMenu,
} from '../components/index.js';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import ReleaseRequest from '../request/index.js';
import GLOBALS from '../globals.js';
import {toastMessage} from '../common/index.js';

import i18n from '../i18n';

export default class Login extends Component{
    constructor(props) {
        super(props);

        this.state = {
            adminName: '',
            adminPassword: '',
            hidden: true,
        };

        this.login = this.login.bind(this);
        this.loginControl = this.loginControl.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    componentDidMount(){
        GLOBALS.promise.view.on('pageProcessing', (page) => {
            this.props.history.replace(page);
        });

    }

    login() {
        var self = this;

        var result = {
            name:  this.state.adminName,
            password : this.state.adminPassword,
        };

        if (!result.name || !result.password) {
            toastMessage('error', i18n.get('fill:blank:fields'));
            return;
        }

        ReleaseRequest.loginOnAuth(result.name, result.password).then((token) => {
            if (token === false) {
                toastMessage('error', i18n.get('username:password:incorrect'));
                return;
            }

            localStorage.setItem('session_ticket', token);
            localStorage.setItem('user', result.name);

            GLOBALS.userName = result.name;

            self.props.history.replace('/');
        })
        .catch(err => {
            self.setState({ loading: false });
        });
    }

    loginControl(event) {
        event.preventDefault();
        this.login();
    }


    render(){
        return (
            <div className="login-page">
                <HeaderMenu/>
                <div className="signin-container-box">
                    <div className="login-background-img">
                        <img
                            src={require('../asset/image/sky.png').default} />
                    </div>
                    <div className="background-transparent"></div>
                    <div className="signin-container">
                        <form className="signin-content" onSubmit={this.loginControl.bind(this)}>
                            <div className="logosvg">
                                <img
                                    alt={'Logo'}
                                    src={require('../asset/image/logo.svg').default} />
                            </div>

                            <div className="upbox-header">
                                <div className="h1-line"></div>
                                <h1>Sign In To Electron UpBox</h1>
                                <div className="h1-line"></div>
                            </div>
                            <div className="login-box">
                                <input
                                    className="input-item"
                                    type="text"
                                    placeholder={i18n.get('email')}
                                    value={this.state.adminName}
                                    onChange={(e) => this.setState({ adminName: e.target.value })}/>
                                <div className="password-item">
                                    <input
                                        className="input-password-item"
                                        type={this.state.hidden ? 'password' : 'text'}
                                        placeholder={i18n.get('password')}
                                        value={this.state.adminPassword}
                                        onChange={(e) => {
                                            this.setState({
                                                adminPassword: e.target.value,
                                            });
                                        }}/>
                                    <span
                                        className="login-password-show"
                                        onClick={this.toggleShow}
                                        title={this.state.hidden ? i18n.get('show:password') : i18n.get('hide:password')}>
                                        {this.state.hidden ? <FaRegEye/> : <FaRegEyeSlash/>}
                                    </span>
                                </div>
                                <div className="login-button-box">
                                    <Button
                                        className="login-button"
                                        backgroundColor="rgb(230, 91, 10)"
                                        text={i18n.get('login')}
                                        onClick={this.login} />
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    history: PropTypes.object,
};
