import React, { Component} from 'react';

import { HashLink as Link} from 'react-router-hash-link';

import PropTypes from 'prop-types';

import './Button.css';

const classNames = require('classnames');

export class Button extends Component {
    render() {
        return (
            <Link to={this.props.link} className={this.props.linkPointer && 'link-pointer'} target={this.props.target === true && '_blank'}>
                <div>
                    <button
                        ref={this.props.buttonRef}
                        title={this.props.title}
                        className={classNames('upbox-button', this.props.type, this.props.className)}
                        style={{
                            backgroundColor: this.props.backgroundColor,
                            color: this.props.color,
                            borderColor: this.props.borderColor || this.props.backgroundColor,
                        }}
                        disabled={this.props.disabled}
                        onClick={this.props.onClick}>
                        {
                            this.props.icon ?
                                <span className={classNames('upbox-button-icon--container', { 'upbox-button-icon--container-top': this.props.icon.float === 'top' })}>
                                    {(this.props.icon.float === 'right' || !this.props.icon.float) && <span>{this.props.text}</span>}

                                    <span
                                        style={{
                                            float: this.props.icon.float }}
                                        className='upbox-button-icon'>{this.props.icon.component}
                                    </span>

                                    {(this.props.icon.float === 'left' || this.props.icon.float === 'top') && <span>{this.props.text}</span>}
                                </span>
                                : <span>{this.props.text}</span>
                        }
                    </button>
                </div>
            </Link>
        );
    }
}

Button.defaultProps = {
    text: '',
    target: false,
    linkPointer: false,
    disabled: false,
    type: null,
    icon: null,
    backgroundColor: '#3979aa',
    color: 'white',
    className: null,
    buttonRef: null,
    title: null,
};

Button.propTypes = {
    text: PropTypes.string,
    target: PropTypes.bool,
    link: PropTypes.string,
    linkPointer: PropTypes.bool,
    borderColor: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.object,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    buttonRef: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

export default Button;
