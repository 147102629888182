module.exports = (client: Object) => {
    /*
     * Product listesini getirir.
     * @param userJid {string} - Mesajları getirilecek kullanıcının jid'si ör: 00001385@bnet.detaysoft.com
     * @param keyword {string} - aranacak keyword
    */

    /*
     * openfire'ın tüm isteklerini json kabul etmek için bu kullanılır
    */
    var defaultHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    client.getAdminList = () => {
        var url = `${client.urls.SERVICE}admin`;

        return client.get(url, defaultHeader)
        .then(res => res.json());
    };

    client.getAdmin = (userName) => {
        var url = `${client.urls.SERVICE}admin/${userName}`;

        return client.get(url, defaultHeader)
        .then(res => res.json());
    };

    client.getOldPassword = (password) => {
        var url = `${client.urls.SERVICE}admin/oldPassword/${password}`;

        return client.get(url, defaultHeader)
        .then(res => res.json());
    };

    client.addAdmin = (mail, name, username, password) => {
        var url = `${client.urls.SERVICE}admin`;

        var body = {
            mail: mail,
            name: name,
            username: username,
            password: password,
        };

        return client.post(url, body, defaultHeader)
        .then(res => res.json());
    };

    client.updateAdmin = (username, name, password, mail, type) => {
        var url = `${client.urls.SERVICE}admin/${username}`;
        var body = {
            name: name,
            password: password,
            mail: mail,
            type: type,
        };
        return client.put(url, body, defaultHeader);
    };

    client.deleteAdmin = (username) => {
        var url = `${client.urls.SERVICE}admin/${username}`;

        return client.delete(url, null, defaultHeader);
    };

    client.enabledAdmin = (username) => {
        var url = `${client.urls.SERVICE}adminEnabled/${username}`;

        return client.put(url, null, defaultHeader);
    };
};

