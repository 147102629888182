import React, { Component } from 'react';

import {
    Popup,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {FaWindowClose } from 'react-icons/fa';

import { ContextMenuTrigger } from 'react-contextmenu';

import ReleaseRequest from '../../../request/index.js';

import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class UpdateAsset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            os: props.os,
        };
        this.updateType = this.updateType.bind(this);
        this.updateOs = this.updateOs.bind(this);
    }

    async updateAsset() {
        const {
            os,
            type,
        } = this.state;

        await ReleaseRequest.updateAsset(GLOBALS.product.bundleId, this.props.releaseId, this.props.assetId, type, os).then((res) => {
            if (res) {
                GLOBALS.promise.view.emit('refreshAssetList');
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                toastMessage('success', i18n.get('asset:success:update'));
            }
        })
        .catch(err => {
            toastMessage('error', i18n.get('asset:updating:error'));
        });
    }

    handleClick() {
        var self = this;
        self.setState({
            disabled: !self.state.disabled,
        });
    }

    updateType = (e) => {
        this.setState({
            type: e.target.value
        });
    }

    updateOs = (e) => {
        this.setState({
            os: e.target.value
        });
    }

    render() {
        var type = [
            'setup',
            'update'
        ];

        var os = [
            'win_32',
            'osx_64'
        ];
        return (
            <Popup
                show={true}
                bigPopup={true}
                header={i18n.get('update:asset')}
                headerButtons={[{
                    type: 'transparent',
                    title: i18n.get('close'),
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div >
                        <div className="asset-form-input">
                            <p className="add-asset-input-title">{i18n.get('type')}</p>
                            {/* <Dropdown
                                className='navbar-header-dropdown'
                                animationPosition='nortwest'
                                items={type}
                                onSelect={(i) => {
                                    this.setState({
                                        type: type[i],
                                    });
                                }
                                }
                                buttonProps={{
                                    className: 'header-text',
                                    text: this.state.type,
                                    color: '#404142',
                                    backgroundColor: '#ccc',
                                    icon: {
                                        float: 'right',
                                        component: <FaChevronDown />,
                                        size: 22,
                                    },
                                }}
                            /> */}

                            <ContextMenuTrigger id="update-asset-context-menu">
                                <div>
                                    <select
                                        className="select-menu"
                                        onChange={this.updateType}>
                                        {
                                            type.map((x, i) => (
                                                <option
                                                    key={x}
                                                    selected={this.state.type === x}
                                                    value={x}>
                                                    {x}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </ContextMenuTrigger>
                        </div>
                        <div className="asset-form-input">
                            <p className="add-asset-input-title">{i18n.get('platform')}</p>
                            {/* <Dropdown
                                className='navbar-header-dropdown'
                                animationPosition='nortwest'
                                items={os}
                                onSelect={(i) => {
                                    this.setState({
                                        os: os[i],
                                    });
                                }
                                }
                                buttonProps={{
                                    className: 'header-text',
                                    text: this.state.os,
                                    color: '#404142',
                                    backgroundColor: '#ccc',
                                    icon: {
                                        float: 'right',
                                        component: <FaChevronDown />,
                                        size: 22,
                                    },
                                }}
                            /> */}

                            <ContextMenuTrigger id="update-asset-context-menu">
                                <div>
                                    <select
                                        className="select-menu"
                                        onChange={this.updateOs}>
                                        {
                                            os.map((x, i) => (
                                                <option
                                                    key={x}
                                                    selected={this.state.os === x}
                                                    value={x}>
                                                    {x}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </ContextMenuTrigger>
                        </div>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text:i18n.get('update'),
                        onClick:() => {this.updateAsset();},
                        className: 'footer-button',
                    }
                ]}
            />
        );
    }
}

UpdateAsset.defaultProps = {
    bundleId: '',
    description: '',
    disabled: false,
};

UpdateAsset.propTypes = {
    type: PropTypes.string,
    os: PropTypes.string,
    releaseId: PropTypes.number,
    assetId: PropTypes.number,
    onClose: PropTypes.func,
};
