import React, { Component } from 'react';
import './Navbar.css';

import PropTypes from 'prop-types';

const classNames = require('classnames');

export class Navbar extends Component {
    render() {
        return (
            <div className={classNames('upbox-navbar', this.props.type, this.props.className)}>
                <div className="upbox-navbar-item">
                    {this.props.left}
                </div>
                <div className="upbox-navbar-item">
                    {this.props.right}
                </div>
            </div>
        );
    }
}

Navbar.defaultProps = {
    left: null,
    center: null,
    right: null,
    type:'light',
};
Navbar.propTypes = {
    left: PropTypes.object,
    center: PropTypes.string,
    right: PropTypes.object,
    type: PropTypes.string,
    className: PropTypes.string,
};

export default Navbar;
