import React, { Component } from 'react';

import {
    Popup,
} from '../../index';

import PropTypes from 'prop-types';

import {FaWindowClose} from 'react-icons/fa';

import { ContextMenuTrigger } from 'react-contextmenu';

import ReleaseRequest from '../../../request/index.js';

import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class AddNewRelease extends Component {
    constructor(props) {
        super(props);
        this.state = {
            release: '',
            notes: '',
            title: '',
            channel: 'stable',
        };

        this.addRelease = this.addRelease.bind(this);
        this.changeDropdownItems = this.changeDropdownItems.bind(this);
    }

    addRelease() {
        const {
            release,
            notes,
            title,
            channel,
        } = this.state;

        if (!release || !notes || !title) {
            toastMessage('warning', i18n.get('fill:blank:fields'));
        }
        else {
            ReleaseRequest.addRelease(GLOBALS.product.bundleId, release, notes, channel, title).then((res) => {
                if (res.data) {
                    GLOBALS.promise.view.emit('refreshReleaseList');
                    GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                    toastMessage('success', i18n.get('release:success:added'));
                }
            })
            .catch(err => {
                if (err.status === 409) {
                    toastMessage('error', i18n.get('another:release:same:name'));
                }
                else if (err.status === 403) {
                    toastMessage('error', i18n.get('channel:must:stable'));
                }
                else {
                    toastMessage('error', i18n.get('release:add:error'));
                }
            });
        }
    }

    changeDropdownItems = (e) => {
        this.setState({
            channel: e.target.value
        });
    }

    render() {
        var dropdownItems = ['stable', 'beta', 'alpha'];

        return (
            <Popup
                show={true}
                bigPopup={true}
                header={i18n.get('add:new:release')}
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    title: i18n.get('close'),
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div>
                        <div className="add-release-form-content">
                            <div>
                                <div className="form-input">
                                    <p className="add-release--input-title">{i18n.get('release:title')}</p>
                                    <input type="text" className="input-style"  onChange={(e) => {
                                        this.setState({
                                            title: e.target.value,
                                        });
                                    }}/>
                                </div>
                                <div className="form-input">
                                    <p className="add-release--input-title">{i18n.get('release:name')}</p>
                                    <input type="text" className="input-style"  onChange={(e) => {
                                        this.setState({
                                            release: e.target.value,
                                        });
                                    }}/>
                                    <p className="add-release--input-message">{i18n.get('match:server:format')}</p>
                                </div>
                                <div className="form-input">
                                    <p className="add-release--input-title">{i18n.get('change:notes')}</p>
                                    <textarea rows="5" cols="47" className="textarea-style" onChange={(e) => {
                                        this.setState({
                                            notes: e.target.value,
                                        });
                                    }}></textarea>
                                </div>
                                <div className="form-input-channel">
                                    <p className="add-release--input-title">{i18n.get('channel')}</p>
                                    {/* <Dropdown
                                        className='navbar-header-dropdown'
                                        animationPosition='nortwest'
                                        items={dropdownItems}
                                        onSelect={(i) => {
                                            this.setState({
                                                channel: dropdownItems[i],
                                            });
                                        }
                                        }
                                        buttonProps={{
                                            className: 'header-text',
                                            text: this.state.channel,
                                            color: '#9f9f9f',
                                            backgroundColor: '#404142',
                                            icon: {
                                                float: 'right',
                                                component: <FaChevronDown />,
                                                size: 22,
                                            },
                                        }}
                                    /> */}
                                    <ContextMenuTrigger id="chat-context-menu">
                                        <div>
                                            <select className="select-menu" onChange={this.changeDropdownItems}>
                                                {
                                                    dropdownItems.map((x, i) => (
                                                        <option
                                                            key={x}
                                                            value={x}>
                                                            {x}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </ContextMenuTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text: i18n.get('upload'),
                        className: 'footer-button',
                        onClick: this.addRelease,
                    }
                ]}
            />
        );
    }
}

AddNewRelease.propTypes = {
    onClose: PropTypes.func,
};
