import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Button,
    Dropdown,
} from '../components/index.js';

import ReleaseRequest from '../request/index.js';

import {
    toastMessage,
} from '../common/index.js';

import {format} from 'timeago.js';

import GLOBALS from '../globals.js';

import i18n from '../i18n';

import {
    FaChevronDown,
} from 'react-icons/fa';

const classNames = require('classnames');

class Release extends Component{
    constructor(props) {
        super(props);
        this.state = {
            pager: {},
            page: 1,
            releaseList: [],
            Stable: [],
            Beta: [],
            Alpha: [],
            activeList: 'releaseList',
        };

        this.releaseList = this.releaseList.bind(this);

        this.user = localStorage.getItem('user');
        GLOBALS.product = JSON.parse(localStorage.getItem('active-product'));
    }

    componentDidMount() {
        if (!this.user) {
            this.props.history.replace('/admin');
            toastMessage('warning', i18n.get('please:first:sign:in'));
        }
        else {
            if (this.props.match.params.bundleId === GLOBALS.product.bundleId){
                this.releaseList();
                GLOBALS.promise.view.on('refreshReleaseList', this.releaseList);
            }
            else {
                this.props.history.replace('/');
            }
        }
    }

    componentWillUnmount() {
        GLOBALS.promise.view.off('refreshReleaseList', this.releaseList);
    }

    componentDidUpdate(nextProps, nextState) {
        if (nextState.page !== this.state.page) {
            this.releaseList();
        }
    }

    async releaseList() {
        await ReleaseRequest.getReleaseList(GLOBALS.product.bundleId, this.state.page).then((res) => {
            if (res) {
                var Stable = res.data.filter(x => x.channel === 'stable');
                var Beta = res.data.filter(x => x.channel === 'beta');
                var Alpha = res.data.filter(x => x.channel === 'alpha');

                res.data.map(x => {
                    if (x.download_check && x.download_check.length > 0){
                        x.download_check.map(data => {
                            if (data.os_id === 1)
                                x.winLink = true;
                            else if (data.os_id)
                                x.osxLink = true;
                        });
                    }
                });

                this.setState({
                    page: window.location.search.substring(6,7) || 1,
                    pager: res.pager,
                    releaseList: res.data,
                    Stable: Stable,
                    Beta: Beta,
                    Alpha: Alpha,
                });
            }
        })
        .catch(err => {
            console.log(err.status);
            if (err.status == 404) {
                this.props.history.replace('/404Page');
            }
        });
    }

    render() {
        var dropdownItems = [
            i18n.get('name'),
            i18n.get('channel'),
            i18n.get('created:date'),
            i18n.get('updated:date'),
            i18n.get('author'),
            ' ',
            ' '
        ];

        var dropdownChannelItems = [
            i18n.get('releaseList'),
            'Stable',
            'Beta',
            'Alpha'
        ];

        const { pager } = this.state;

        if (!this.user) {
            return (
                <div></div>
            );
        }
        return (
            <div className="release-container">
                <div className="release-container-up-header">
                    <div className="release-header-star-img"></div>
                    <div className='release-container-header-text-icon-container'>
                        <div className="release-container-header-icon">
                            <img
                                alt={i18n.get('product:icon')}
                                className='product-icon'
                                src={GLOBALS.product.icon}
                            />
                        </div>
                        <span className='release-container-header-text'>{`${GLOBALS.product.bundleName} ${i18n.get('versions')}`}</span>
                    </div>
                </div>
                <div className="add-version-button-container">
                    <Button
                        className="add-version-button"
                        backgroundColor="#474747"
                        borderColor="#fff"
                        color='#fff'
                        onClick={() => {
                            GLOBALS.promise.view.emit('onGroupProcess', {
                                show: true,
                                type: 'addNewRelease',
                                props: {

                                },
                            });
                        }}
                        text={i18n.get('add:new:release')} />
                </div>
                <div className="release-container-content">
                    <div className="release-content">
                        <div className="release-channel-header">
                            <span className='release-container-header-text'>
                                {i18n.get('channel')}: {this.state.activeList === 'releaseList' ? i18n.get('releaseList') : this.state.activeList}
                            </span>
                            <Dropdown
                                className='navbar-header-dropdown'
                                animationPosition='norteast'
                                items={dropdownChannelItems}
                                onSelect={(i) => {
                                    switch (dropdownChannelItems[i]) {
                                        case i18n.get('releaseList'):
                                            this.setState({
                                                activeList: 'releaseList',
                                            });
                                            break;
                                        case 'Stable':
                                            this.setState({
                                                activeList: 'Stable',
                                            });
                                            break;
                                        case 'Beta':
                                            this.setState({
                                                activeList: 'Beta',
                                            });
                                            break;
                                        case 'Alpha':
                                            this.setState({
                                                activeList: 'Alpha',
                                            });
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                                buttonProps={{
                                    text: i18n.get('channel'),
                                    type: 'transparent',
                                    color: '#9f9f9f',
                                    icon: {
                                        float: 'left',
                                        component: <FaChevronDown />,
                                        size: 22,
                                    },
                                }}
                            />
                        </div>

                        <div className="release-list-container">
                            {
                                this.state[this.state.activeList].length === 0 ?
                                    <div className='not-found-component-container'>
                                        <span className='not-found-component-header'>
                                            {this.state.activeList === 'releaseList' ? i18n.get('not:release:this:product') : i18n.get('not:release:this:channel') }
                                        </span>
                                    </div> :
                                    <div className="table-container">
                                        <table className="table-content">
                                            <tbody>
                                                <tr className="ust">
                                                    {
                                                        dropdownItems.map((x, i) => {
                                                            return <th key={i}>{x}</th>;
                                                        })
                                                    }
                                                </tr>
                                                {
                                                    this.state[this.state.activeList].map((x, i) => {
                                                        return <tr key={i}>
                                                            <td>{x.version}</td>
                                                            <td>{x.channel}</td>
                                                            <td>{format(x.created_date)}</td>
                                                            <td>{format(x.updated_date)}</td>
                                                            <td>{x.author_name}</td>
                                                            <td>
                                                                <div className="table-title-item">
                                                                    <Button
                                                                        className="more-button"
                                                                        backgroundColor="#474747"
                                                                        borderColor="#fff"
                                                                        color='#fff'
                                                                        onClick={() => {
                                                                            GLOBALS.promise.view.emit('onGroupProcess', {
                                                                                show: true,
                                                                                type: 'moreInfo',
                                                                                props: {
                                                                                    res: x,
                                                                                },
                                                                            });
                                                                        }}
                                                                        text={i18n.get('more')} />
                                                                </div>
                                                            </td>
                                                            <td >
                                                                <Dropdown
                                                                    animationPosition='nortwest'
                                                                    items={
                                                                        [
                                                                            {
                                                                                'itemClass': !x.winLink && 'product-more-button-disabled',
                                                                                'name': 'Windows Setup',
                                                                                'link': `/api/download/${GLOBALS.product.bundleId}/${x.version}/${x.channel}/win_32`,
                                                                            },
                                                                            {
                                                                                'itemClass': !x.osxLink && 'product-more-button-disabled',
                                                                                'name': 'macOs Setup',
                                                                                'link': `/api/download/${GLOBALS.product.bundleId}/${x.version}/${x.channel}/osx_64`,
                                                                            }
                                                                        ]
                                                                    }
                                                                    buttonProps={{
                                                                        className: 'upbox-button more-button',
                                                                        backgroundColor: '#474747',
                                                                        borderColor: '#fff',
                                                                        color: '#fff',
                                                                        title: i18n.get(this.state.activeList === 'releaseList' ? null : this.state.activeList ),
                                                                        text: 'İndir',
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>;
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div className="release-pagination-container">
                                            {pager.pages && pager.pages.length &&
                                                <ul className="release-pagination-container-ul">
                                                    <li onClick={() => { this.setState({ page: 1 }); }} className="release-pagination-container-li">
                                                        <Link
                                                            to={{ search: '?page=1' }}
                                                            className="page-link">
                                                            First
                                                        </Link>
                                                    </li>
                                                    <li onClick={() => { this.setState({ page: pager.currentPage - 1 }); }} className="release-pagination-container-li">
                                                        <Link
                                                            to={{ search: `?page=${pager.currentPage - 1}` }}
                                                            className="page-link">
                                                            Previous
                                                        </Link>
                                                    </li>
                                                    {pager.pages.map(page =>
                                                        <li onClick={() => { this.setState({ page: page }); }} key={page} className="release-pagination-container-li">
                                                            <Link
                                                                to={{ search: `?page=${page}` }}
                                                                className={classNames(
                                                                    'page-link',
                                                                    { 'release-pagination-active': page == (window.location.search.substring(6, 7) || 1) })}>
                                                                {page}
                                                            </Link>
                                                        </li>
                                                    )}
                                                    <li onClick={() => { this.setState({ page: pager.currentPage + 1 }); }} className="release-pagination-container-li">
                                                        <Link
                                                            to={{ search: `?page=${pager.currentPage + 1}` }}
                                                            className='page-link'>
                                                            Next
                                                        </Link>
                                                    </li>
                                                    <li onClick={() => { this.setState({ page: pager.totalPages }); }} className="release-pagination-container-li">
                                                        <Link
                                                            to={{ search: `?page=${pager.totalPages}` }}
                                                            className="page-link">
                                                            Last
                                                        </Link>
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Release;

Release.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};
