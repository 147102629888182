import React, { Component } from 'react';

export default class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="header-container">
                <img className="header-star-img"/>
                <div className="header-left-content">
                    <div className="header-title">
                        <h1>Merkezi Uygulama İndirme <br/> Platformu</h1>
                    </div>
                    <div className="header-title-content">
                        <p>
                            Geliştirmiş olduğumuz uygulamalara aşağıda bulunan indirme linklerine tıklayarak ulaşabilirsiniz. <br/> Uygulamalarda hata mesajı alıyorsanız <br/>
                            <b>“bnetsupport@detaysoft.com”</b> destek ekibi ile <br/> iletişime geçebilirsiniz.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
