import React, { Component } from 'react';

import Product from './product';

import {
    Footer,
    Header,
} from '../components';

export default class Start extends Component {
    render() {
        return (
            <div className="start-upbox-body">
                <Header/>
                <Product/>
                <Footer/>
            </div>
        );
    }
}
