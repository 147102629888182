import React, { Component } from 'react';

import {
    Header, Footer,
} from './index.js';

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="start-upbox-body">
                <Header/>
                <div className="info">
                    <div className="info-container">
                        <div className="info-container-left">
                            <img className="info-left-img" src={require('../asset/image/mockupImg.png').default} />
                        </div>
                        <div className="info-container-right">
                            <h1 className="info-list-title">{'DIŞARIDAN YÜKLENEN UYGULAMALARA NASIL İZİN VERİRİM?'}</h1>
                            <ul className="info-list-items">
                                <li>
                                    <span>1-AYARLAR</span>
                                    <p>{'Cihazınızdan \'Ayarlar\' menüsüne gidin.'}</p>
                                </li>
                                <li>
                                    <span>2-GENEL</span>
                                    <p>{'Ayarların içerisine girdikten sonra \'Genel\' seçeneğini seçmeniz gerekmektedir.'}</p>
                                </li>
                                <li>
                                    <span>3-AYGIT YÖNETİMİ (PROFİL)</span>
                                    <p>{'HTML entities must be escaped'}</p>
                                </li>
                                <li>
                                    <span>1-GÜVEN</span>
                                    <p>{'Geliştirici adına tıklandıktan sonra \'Güven\' butonuna basarak, uygulamayı tekrardan çalıştırabilirsiniz.'}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
