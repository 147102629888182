import WildEmitter from 'wildemitter';

// Global handler için yapılan bir WildEmitter
function _CC () { }
WildEmitter.mixin(_CC);

class GLOBALS {
    constructor() {
        this.promise = {
            view: new _CC(),
        };

        this.product = {
            bundleId: null,
            bundleName: null,
            icon: null,
        };

        this.active = {
            popup: false,
        };

        this.userName = null;

        this.productLinks = [
            'ios_iphone',
            'ios_ipad',
            'android',
            'windows_desktop',
            'macos_desktop',
            'linux_desktop',
            'web_address'
        ];
    }
}
export default (new GLOBALS());
