module.exports = {

    // Start.js için

    'download': 'İndir',

    // Login

    'username:password:incorrect' : 'Kullanıcı adı veya şifre yanlış tekrar deneyiniz',

    'forgot:password' : 'Şifreni mi unuttun?',

    'login' : 'Giriş',

    'password' : 'Şifre',

    'email' : 'Email',

    'please:first:sign:in' : 'Lütfen önce giriş yapınız',

    // Header Menu

    'electron:upbox': 'Electron UpBox',

    'home' : 'Ana Sayfa',

    'admin' : 'Admin',

    'products' : 'Ürünler',

    'menu': 'Menü',

    'profile': 'Profil',

    'sign:out' : 'Çıkış Yap',

    // Product

    'disabledProductList' : 'Devre Dışı Ürünler',

    'enabledProductList' : 'Etkin Ürünler',

    'product:list' : 'Ürünler Listesi',

    'add:new:product' : 'Ürün Ekle',

    'show:more' : 'Daha Fazla Bilgi Göster',

    'edit' : 'Düzenle',

    'delete' : 'Sil',

    'not:found:product' : 'Herhangi bir ürün bulunmamaktadır.',

    'no:version' : 'Versiyon Yok',

    'choose:type:product:add' : 'Ekleyeceğiniz ürünün tipini şeçiniz',

    'please:select:type' : 'Lütfen Tip Seçiniz',

    'ios:iphone' : 'IPhone',

    'ios:ipad' : 'IPad',

    'android' : 'Android',

    'windows:desktop' : 'Windows Masaüstü',

    'macos:desktop' : 'MacOS Masaüstü',

    'linux:desktop' : 'Linux Masaüstü',

    'web:address' : 'Web Adresi',

    'product:links' : 'Ürün Linkleri',


    // Release

    'name': 'Adı',

    'channel' :'Kanal',

    'releaseList' : 'Tümü',

    // 'asset:count' : 'Dağıtım Sayısı',

    'created:date' : 'Oluşturulma Tarihi',

    'updated:date' : 'Güncelleme Tarihi',

    'author' : 'Yetkili',

    'add:new:release' : 'Yeni Versiyon Ekle',

    'more' : 'Daha Fazla',

    'not:release:this:product' : 'Bu ürüne ait herhangi bir versiyon bulunmamaktadır.',

    'not:release:this:channel' : 'Bu kanala ait herhangi bir versiyon bulunmamaktadır.',

    'not:version:this:release' : 'Bu versiyona ait dağıtım bulunmamaktadır',

    'downloading' : 'İndiriliyor..',

    'versions' : 'Versiyonları',

    // Add A New Admin

    'admin:name': 'Admin Adı',

    'admin:mail': 'Admin Mail',

    'admin:username': 'Admin Kullanıcı Adı',

    'admin:password': 'Admin Şifresi',

    'add:new:admin' : 'Admin Ekle',

    'add' : 'Ekle',

    'admin:success:added' : 'Admin başarıyla eklendi.',

    'admin:add:error' : 'Admin eklenirken bir hata oluştu',

    'another:admin:same:name' : 'Aynı isimde başka admin mevcut değiştirip deneyiniz.',

    'show:password' : 'Şifreyi Göster',

    'hide:password' : 'Şifreyi Gizle',

    // Update Admin

    'update:admin' : 'Admin Güncelle',

    'new:password' : 'Yeni Şifre',

    'old:password' : 'Eski Şifre',

    'admin:success:updated' : 'Admin başarıyla güncellendi.',

    'admin:update:error' : 'Admin güncellenirken sorun oluştu',

    'entered:wrong:password': 'Şifreyi yanlış girdiniz tekrar deneyiniz.',

    'change:password' : 'Şifre değiştir',

    // Delete Admin

    'admin:success:delete' : 'Admin başarıyla silindi.',

    'admin:delete:error' : 'Admin silinirken sorun oluştu',


    // Admin Profile

    'admin:list' : 'Admin Listesi',

    'enabledAdminsList' : 'Etkin Adminler',

    'disabledAdminsList' : 'Pasif Adminler',

    'admin:list:show' : 'Admin Listesini Göster',

    'admin:list:hide' : 'Admin Listesini Gizle',

    'not:found:admin' : 'Herhangi bir admin bulunmamaktadır.',

    // More Info

    'platform' : 'Platform',

    'download:count' : 'İndirilme Sayısı',

    'upload:date' : 'Yüklenme Tarihi',

    'not:found:asset' : 'Herhangi bir dağıtım bulunmamaktadır.',

    'add:asset' : 'Dağıtım Ekle',

    'assets' : 'Dağıtımlar',

    // New Asset

    'win_32' : 'Windows 32 bit',

    'osx_64' : 'OSX 64 bit',

    'add:new:asset' : 'Yeni Dağıtım Ekle',

    'asset:type': 'Dağıtım Tipi',

    'choose:file':'Dosya Seç',

    'please:attach:files' : 'Lütfen dosya ekleyiniz',

    'added:asset:success' : 'Dağıtım başarıyla eklendi',

    'could:not:add:asset' : 'Dağıtımı ekleyemediniz tekrar deneyiniz.',

    'click:select:file' : 'Dosya Seçmek İçin Tıklayınız.',


    // Update Asset

    'type'  : 'Tipi',

    // Add New Product

    'product:name' : 'Ürün Adı',

    'product:icon' : 'Ürün İconu',

    'product:bundleId': 'Ürün BundleId',

    'product:description' : 'Ürün Açıklaması',

    'close' : 'Kapat',

    'added:product:success' : 'Ürün başarıyla eklendi',

    'another:product:same:name' : 'Aynı isimde başka ürün mevcut değiştirip deneyiniz.',

    'fill:blank:fields' : 'Boş alanları doldurup deneyiniz.',

    'could:not:add:product' : 'Ürünü ekleyemediniz tekrar deneyiniz.',

    'click:select:picture' : 'Resim Seçmek İçin Tıklayınız.',

    // Delete Product

    'product:deleting:error' : 'Ürün silinrken bir hata oluştu.',

    'product:success:delete' : 'Ürün başarıyla silindi.',

    'are:you:sure:want:delete' : 'Silmek istediğinize emin misiniz?',

    'product:delete' : 'ürününü sil',

    'could:not:special:characters' : 'Yalnızca harf(A-z), rakam(0-9), orta ve alt çizgi (_, -) kullanılabilir.',

    // Update Product

    'update' : 'Güncelle',

    'enabled': 'Etkinleştir',

    'product:disable': 'Ürün Görünürlüğü',

    'disabled': 'Devre Dışı Bırak',

    'product:update' :'Ürünü Güncelle',

    'description:cannot:empty' : 'Açıklama alanı boş bırakılamaz.',

    'product:success:update' : 'Ürün başarıyla güncellendi.',

    'product:updating:error' : 'Ürün güncellenirken bir hata oluştu',


    // Add New Release

    'release:title' : 'Versiyon Başlığı',

    'release:name' : 'Versiyon Adı',

    'change:notes' : 'Yapılan Değişiklikler',

    'release:success:added' : 'Versiyon başarıyla eklendi.',

    'release:add:error' : 'Versiyon eklenirken bir hata oluştu',

    'another:release:same:name' : 'Aynı isimde başka versiyon mevcut değiştirip deneyiniz.',

    'upload' : 'Yükle',

    'match:server:format' : 'Sunucu biçimiyle eşleşmelidir (ör.1.0.1).',

    'channel:must:stable' : 'Kanalınız stable olmalıdır.',

    // Delete Release

    'release:success:delete' : 'Versiyon başarıyla silindi.',

    'release:deleting:error' : 'Versiyon silinirken bir hata ile karşılaşıldı.',

    'update:release' : 'Versiyonu Güncelle',

    'you:are:deleting:version' : 'versiyonunu siliyorsunuz',

    'cannot:delete:stable:channel' : 'Stable kanalını silemezsiniz.',

    // Update Release

    'release:success:update' : 'Versiyon başarıyla güncellendi.',

    'release:updating:error' : 'Versiyon güncellenirken bir hata oluştu',


    // Delete Asset

    'asset:success:delete' : 'Dağıtım başarıyla silindi.',

    'asset:deleting:error' : 'Dağıtım silinirken bir hata ile karşılaşıldı.',

    'asset:delete' : 'Dağıtım Siliniyor',

    // Update Asset

    'asset:success:update' : 'Dağıtım başarıyla güncellendi.',

    'asset:updating:error' : 'Dağıtım güncellenirken bir hata ile karşılaşıldı.',

    'update:asset' : 'Dağıtımı Güncelle',

    // Not Found

    'back:home' : 'Ana Sayfaya Dön',
};


