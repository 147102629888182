import React, { Component } from 'react';

import {
    AddProductPopup,
    Button,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {FaWindowClose} from 'react-icons/fa';

import ReleaseRequest from '../../../request/index.js';

import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class UpdateProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            description: props.description,
            icon: props.icon,
            links: props.links,
        };

        this.links = {};

        GLOBALS.productLinks.forEach(x => {
            this.links[x] = React.createRef();
        });

        this.updateProduct = this.updateProduct.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
    }

    async updateProduct() {
        const {
            description,
            disabled,
            icon,
        } = this.state;

        const links = {};

        Object.keys(this.links).map(x => {
            if (this.links[x].current.value) {
                links[x] = this.links[x].current.value;
            }
        });

        if (!description) {
            toastMessage('warning', i18n.get('description:cannot:empty'));
        }
        else {
            var productDisabled = disabled ? '1' : '0';
            await ReleaseRequest.updateProduct(this.props.bundleId, description, productDisabled, icon, links).then((res) => {
                if (res) {
                    GLOBALS.promise.view.emit('refreshProductList');
                    GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                    toastMessage('success', i18n.get('product:success:update'));
                }
            })
            .catch(err => {
                if (err.status === 409)
                    toastMessage('error', i18n.get('another:product:same:name'));
                else
                    toastMessage('error', i18n.get('product:updating:error'));
            });
        }
    }

    handleClick() {
        var self = this;
        self.setState({
            disabled: !self.state.disabled,
        });
    }

    onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    icon: e.target.result,
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    render() {
        return (
            <AddProductPopup
                show={true}
                bigPopup={true}
                header={i18n.get('product:update')}
                headerButtons={[{
                    type: 'transparent',
                    title: i18n.get('close'),
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div className="update-release-form-content">
                        <div className="form-input">
                            <p className="add-release--input-title">{i18n.get('product:icon')}</p>
                            <img
                                id="target"
                                alt={i18n.get('product:icon')}
                                className="product-icon"
                                src={this.state.icon}/>
                            <label className="input-style-file"> {i18n.get('click:select:picture')}
                                <input type="file" accept="image/*" onChange={this.onImageChange} />
                            </label>
                        </div>
                        <div className="form-input">
                            <p className="add-release--input-title">{i18n.get('product:disable')}</p>
                            <Button
                                color='#404142'
                                backgroundColor={this.state.disabled ? '#7cbb40' : '#e06b6b'}
                                onClick={this.handleClick}
                                link="/"
                                text={this.state.disabled ? i18n.get('enabled') : i18n.get('disabled')}/>
                        </div>
                        <div className="form-input">
                            <p className="add-release--input-title">{i18n.get('product:description')}</p>
                            <textarea
                                rows="5"
                                cols="47"
                                value={this.state.description}
                                className="textarea-style"
                                onChange={(e) => {
                                    this.setState({
                                        description: e.target.value,
                                    });
                                }}>
                                {this.state.description}
                            </textarea>
                        </div>
                        {
                            GLOBALS.productLinks.map((x, i) => (
                                <div
                                    key={i}
                                    className="form-input">
                                    <p
                                        className="add-release--input-title">
                                        {x}
                                    </p>
                                    <input
                                        ref={this.links[x]}
                                        defaultValue={this.state.links[x]}
                                        type="text"
                                        placeholder={x}
                                        className="input-style"
                                    />

                                </div>
                            ))
                        }
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text:i18n.get('update'),
                        onClick:() => {this.updateProduct();},
                        className: 'footer-button',
                    }
                ]}
            />
        );
    }
}

UpdateProduct.defaultProps = {
    bundleId: '',
    description: '',
    disabled: false,
};

UpdateProduct.propTypes = {
    bundleId: PropTypes.string,
    disabled: PropTypes.number,
    description: PropTypes.string,
    icon: PropTypes.string,
    onClose: PropTypes.func,
    links: PropTypes.object,
};
