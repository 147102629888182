import React, { Component } from 'react';

import {
    Popup,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {FaWindowClose} from 'react-icons/fa';

import ReleaseRequest from '../../../request/index.js';

import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class DeleteAsset extends Component {

    async deleteAsset() {
        const {
            releaseId,
            assetId,
        } = this.props;

        await ReleaseRequest.deleteAsset(GLOBALS.product.bundleId, releaseId, assetId).then((res) => {
            if (res) {
                GLOBALS.promise.view.emit('refreshAssetList');
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                toastMessage('success', i18n.get('asset:success:delete'));
            }
        })
        .catch(err => {
            toastMessage('error', i18n.get('asset:deleting:error'));
        });
    }

    render() {
        return (
            <Popup
                show={true}
                header={i18n.get('asset:delete')}
                headerButtons={[{
                    type: 'transparent',
                    title: i18n.get('close'),
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div style={{color:'#fff'}}>
                        <span>{i18n.get('are:you:sure:want:delete')}</span>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text: i18n.get('delete'),
                        onClick:() => {
                            this.deleteAsset();},
                        className: 'footer-button',
                    }
                ]}
            />
        );
    }
}

DeleteAsset.defaultProps = {
    releaseId: '',
    assetId: '',
};

DeleteAsset.propTypes = {
    releaseId: PropTypes.number,
    assetId: PropTypes.number,
    onClose: PropTypes.func,
};