module.exports = (client: Object) => {
    /*
     * Product listesini getirir.
     * @param userJid {string} - Mesajları getirilecek kullanıcının jid'si ör: 00001385@bnet.detaysoft.com
     * @param keyword {string} - aranacak keyword
    */

    var defaultHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    client.getProductList = () => {
        var url = `${client.urls.SERVICE}product`;

        return client.get(url, defaultHeader, true)
        .then(res => res.json())
        .then(res => {
            var topProducts = {};
            res.data.forEach(x => topProducts[x.bundle_id] = 0);
            topProducts['com.detaysoft.bnetdesktop'] = 10;
            topProducts['com.detaysoft.mobile.bnetportal'] = 9;
            topProducts['com.detaysoft.bnet.rehber'] = 8;
            topProducts['com.detaysoft.bnetpass'] = 7;
            topProducts['com.detaysoft.docs'] = 6;

            res.data.sort((a, b) => topProducts[b.bundle_id] - topProducts[a.bundle_id]);

            return res;
        })
        .then(res => {
            var specialProducts = {};
            specialProducts['com.detaysoft.bnetdesktop'] = true;
            specialProducts['com.detaysoft.mobile.bnetportal'] = true;
            specialProducts['com.detaysoft.bnet.rehber'] = true;
            specialProducts['com.detaysoft.bnetpass'] = true;
            specialProducts['com.detaysoft.docs'] = true;

            res.data.forEach(x => {
                if (!specialProducts[x.bundle_id]) {
                    x.special = true;
                }
            });

            return res;
        });
    };


    client.addProduct = (product) => {
        var url = `${client.urls.SERVICE}product`;

        return client.post(url, product, defaultHeader)
        .then(res => res.json());
    };

    client.updateProduct = (bundleId, description, disabled, icon, links) => {
        var url = `${client.urls.SERVICE}product/${bundleId}`;
        var body = {
            description: description,
            disabled: disabled,
            icon: icon,
            links: links,
        };
        return client.put(url, body, defaultHeader);
    };

    client.deleteProduct = (bundleId) => {
        var url = `${client.urls.SERVICE}product/${bundleId}`;

        return client.delete(url, null, defaultHeader);
    };
};

