import React, { Component } from 'react';

import {
    Button,
} from '../components/index.js';

import i18n from '../i18n';

export default class NotFound extends Component {
    render() {
        return (
            <div className='notfound-upbox-container'>
                <div className="notfound-container">
                    <div className="notfound-content">
                        <h1>404</h1>
                        <p>Page Not Found</p>
                        <Button
                            text={i18n.get('back:home')}
                            backgroundColor="rgb(51, 56, 57)"
                            link={'/'}
                            className="notfound-button" />
                    </div>
                </div>
            </div>
        );
    }
}