import React, { Component } from 'react';

import {
    Popup,
} from '../../index';

import '../../../asset/css/style.css';

import PropTypes from 'prop-types';

import {FaWindowClose} from 'react-icons/fa';

import ReleaseRequest from '../../../request/index.js';

import GLOBALS from '../../../globals.js';

import {toastMessage} from '../../../common/index.js';

import i18n from '../../../i18n';

export default class DeleteAdmin extends Component {

    async deleteAdmin(userName) {
        await ReleaseRequest.deleteAdmin(userName).then((res) => {
            if (res) {
                GLOBALS.promise.view.emit('refreshAdminList');
                GLOBALS.promise.view.emit('onGroupProcess', { show: false });
                toastMessage('success', i18n.get('admin:success:delete'));
            }
        })
        .catch(err => {
            toastMessage('error', i18n.get('admin:delete:error'));
        });
    }

    render() {
        return (
            <Popup
                show={true}
                header={'Admin Siliniyor'}
                headerButtons={[{
                    type: 'transparent',
                    title: i18n.get('close'),
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={() => (
                    <div style={{color:'#2b2b2b'}}>
                        <span>{i18n.get('are:you:sure:want:delete')}</span>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text: i18n.get('delete'),
                        onClick:() => {
                            this.deleteAdmin(this.props.userName);},
                        className: 'footer-button',
                    }
                ]}
            />
        );
    }
}

DeleteAdmin.defaultProps = {
    bundleId: '',
    productName: '',
};

DeleteAdmin.propTypes = {
    bundleId: PropTypes.string,
    productName: PropTypes.string,
    userName: PropTypes.string,
    onClose: PropTypes.func,
};
