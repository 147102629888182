import React, { Component } from 'react';
import GLOBALS from '../../globals.js';
import {
    Popup,
    Button,
} from '../index';

import '../../asset/css/style.css';

import PropTypes from 'prop-types';

import {format} from 'timeago.js';

import {
    FaWindowClose,
    FaEdit,
    FaTrash,
} from 'react-icons/fa';

import i18n from '../../i18n';

import ReleaseRequest from '../../request/index.js';

export default class MoreInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assetList: [],
        };
    }

    componentDidMount() {
        this.assetList(this.props.res.id);

        GLOBALS.promise.view.on('refreshAssetList', (releaseId) => {this.assetList(releaseId);});
    }

    componentWillUnmount() {
        GLOBALS.promise.view.off('refreshAssetList', this.assetList);
    }

    assetList(releaseId) {
        ReleaseRequest.getAsset(GLOBALS.product.bundleId, releaseId).then((res) => {
            if (res && res.data) {
                this.setState ({
                    assetList: res.data,
                });
            }
        });
    }

    render() {
        const {
            res,
        } = this.props;

        var self = this;
        var dropdownItems = [
            i18n.get('name'),
            i18n.get('platform'),
            i18n.get('download:count'),
            i18n.get('upload:date'),
            i18n.get('updated:date'),
            '',
            ''
        ];

        return (
            <Popup
                show={true}
                bigPopup={true}
                header={res.version}
                renderHeader={()=>(
                    <div className="more-form-container-box">
                        <div className="more-form-content-container">
                            <div className="more-form-form-input">
                                <p className="more-form-input-title-row">{i18n.get('release:name')}</p>
                                <p className="more-form-input-title">{res.version}</p>
                            </div>
                            <div className="more-form-form-input">
                                <p className="more-form-input-title-row">{i18n.get('release:title')}</p>
                                <p className="more-form-input-title">{res.title}</p>
                            </div>
                            <div className="more-form-form-input">
                                <p className="more-form-input-title-row">{i18n.get('change:notes')}</p>
                                <p className="more-form-input-title">{res.notes}</p>
                            </div>
                        </div>
                        <Button
                            type='transparent'
                            color='#ccc'
                            icon={{
                                component: <FaWindowClose />,
                                size: 15,
                            }}
                            onClick={this.props.onClose} />
                    </div>
                )
                }
                headerButtons={[{
                    type: 'transparent',
                    color: '#ccc',
                    icon: {
                        component: <FaWindowClose />,
                        size: 25,
                    },
                    onClick: this.props.onClose,
                }]}
                renderContent={(i) => (
                    <div className="more-form-content">
                        {
                            self.state.assetList.length === 0 ?
                                <div className='not-found-component-container'>
                                    <span className='not-found-component-header'>{i18n.get('not:found:asset')}</span>
                                </div>
                                :
                                <div className="form-table-style">
                                    <h3>{i18n.get('assets')}</h3>
                                    <table className="form-table-container">
                                        <tbody>
                                            <tr key={i}>
                                                {
                                                    dropdownItems.map((x, i) => {return <th key={i}>{x}</th>;})
                                                }
                                            </tr>
                                            {
                                                self.state.assetList.map((x, i) => {
                                                    return (
                                                        <tr key={i} >
                                                            <td>{x.name}</td>
                                                            <td>{x.os_name}</td>
                                                            <td>{x.download_count}</td>
                                                            <td>{format(x.created_date)}</td>
                                                            <td>{format(x.updated_date)}</td>
                                                            <td>
                                                                <Button
                                                                    type='transparent'
                                                                    backgroundColor="#078fe9"
                                                                    borderColor="#fff"
                                                                    color='#fff'
                                                                    title={i18n.get('edit')}
                                                                    icon={{
                                                                        component: <FaEdit />,
                                                                        size: 25,
                                                                    }}
                                                                    onClick={() => {
                                                                        GLOBALS.promise.view.emit('onGroupProcess', {
                                                                            show: true,
                                                                            type: 'updateAsset',
                                                                            props: {
                                                                                releaseId: x.release_id,
                                                                                assetId: x.id,
                                                                                type: x.file_type,
                                                                                os: x.os_name,
                                                                            },
                                                                        });
                                                                    }} />
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    type = 'transparent'
                                                                    backgroundColor="#078fe9"
                                                                    borderColor="#fff"
                                                                    title={i18n.get('delete')}
                                                                    color='#d64f4f'
                                                                    icon={{
                                                                        component: <FaTrash />,
                                                                        size: 25,
                                                                    }}
                                                                    onClick={() => {
                                                                        GLOBALS.promise.view.emit('onGroupProcess', {
                                                                            show: true,
                                                                            type: 'deleteAsset',
                                                                            props: {
                                                                                releaseId: x.release_id,
                                                                                assetId: x.id,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                        <div className="add-release-button-container">
                            <Button
                                className="add-asset-button"
                                backgroundColor="#078fe9"
                                borderColor="#fff"
                                color='#fff'
                                onClick={() => {
                                    GLOBALS.promise.view.emit('onGroupProcess', {
                                        show: true,
                                        type: 'addAsset',
                                        props: {
                                            releaseId: res.id,
                                        },
                                    });
                                }}
                                text={i18n.get('add:asset')} />
                        </div>
                    </div>
                )}
                footerButtons={[
                    {
                        color: '#fff',
                        backgroundColor: '#474747',
                        text:i18n.get('edit'),
                        className: 'footer-button',
                        onClick:() => {
                            GLOBALS.promise.view.emit('onGroupProcess', {
                                show: true,
                                type: 'updateRelease',
                                props: {
                                    res: res,
                                },
                            });
                        },
                    },
                    {
                        backgroundColor: '#d64f4f',
                        color: '#fff',
                        text: i18n.get('delete'),
                        className: 'footer-button',
                        onClick:() => {
                            GLOBALS.promise.view.emit('onGroupProcess', {
                                show: true,
                                type: 'deleteRelease',
                                props: {
                                    releaseId: res.id,
                                    releaseVersion: res.version,
                                },
                            });
                        },
                    }
                ]}
            />
        );
    }
}

MoreInfo.propTypes = {
    res: PropTypes.object,
    onClose: PropTypes.func,
};
